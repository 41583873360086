import Vue from 'vue'
import VueRouter from 'vue-router'
import Poster from '../views/Poster'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // component: Poster
    redirect: '/public_course'
  },
  {
    path: '/public_course',
    component: () => import('../views/PublicCourse')
  },
  {
    path: '/lecturer_introduce',
    component: () => import('../views/LecturerIntroduce')
  },
  {
    path: '/task',
    component: () => import('../views/Task')
  },
  {
    path: '/verify',
    component: () => import('../views/Verify')
  },
  {
    path: '/sale_qrcode',
    component: () => import('../views/SaleQrcode')
  },
  {
    path: '/test',
    component: () => import('../views/test')
  },
  {
    path: '/jsx',
    component: () => import('../views/jsx.vue')
  },
  {
    path: '/test1',
    component: () => import('../views/test1')
  },
  {
    path: '/teacher2',
    component: () => import('../views/teacher2')
  },
  {
    path: '/teacher-poster2',
    component :()=>import('../views/TeacherPoster2')
  },
  {
    path: '/lecturer_introduce_noimg',
    component :()=>import('../views/lecturerIntroduceNoImg')
  },
  {
    path:'/templates',
    component : ()=>import('../views/templates')
  },
  {
    path:'/reward',
    component : ()=>import('../views/Reward2')
  }
]

const router = new VueRouter({
  routes
})

export default router
