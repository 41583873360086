import domtoimage from '../utils/domtoimage'
import html2canvas from 'html2canvas'

const toImage = {

  domtoimg(node, the) {
    var that = the
    console.log('domtoimg');
    // var node = document.getElementById('imgBox');
    console.log(node.offsetHeight);
    console.log(node.offsetWidth);
    let width = node.offsetWidth
    let height = node.offsetHeight
    // ios  => html2cavas
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      console.log('ios');
      console.log(this);
      this.saveHtml2Image(node)
    }
    // 安卓 || pc => domtoimage
    else {
      console.log(this);
      console.log('andriod或pc');
      console.log(node);
      domtoimage.toPng(node, { scale: 4, width, height })
        .then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          img.style.cssText =
            "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:9;";
          img.id = "poster-Img-new"
          document.body.appendChild(img);
          if (that) that.loading = false
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        })
    }
  },

  //将html结构转换为图片
  saveHtml2Image(node, loading) {

    //转换图片时 将高度归0 避免出现顶部白边
    window.pageYoffset = 0;
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;

    html2canvas(node, {
      useCORS: true,
      allowTaint: true,
    }).then(async canvas => {
      const img = document.createElement("img");
      let src = canvas.toDataURL("image/jpeg", 0.9)
      img.src = src
      document.body.appendChild(img);
      img.style.cssText =
        "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:9;";
      img.id = "poster-Img-new"
      console.log('html2cavas');
    });
  },

}

export default toImage