import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Loading from './components/Loading'
import toImage from './common/toImage'

Vue.config.productionTip = false
Vue.component('loading', Loading)
Vue.prototype.$toImage = toImage

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
