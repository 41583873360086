<template>
  <div>
    <div
      class="box"
      id="imgBox"
    >
      <!-- 背景图 -->
      <img
        src="../assets/bg.jpg"
        alt=""
        style="max-width:420px"
      >
      <!-- 主标题 -->
      <div
        :class="{'title-box':flag}"
        :style="[{top:(titleTop?titleTop:'10%')}]"
      >霍格沃兹测试学社霍格沃兹测试学社</div>
      <!-- 副标题 -->

      <div
        :class="{'subhead-box':flag}"
        :style="[{top:(subheadTop?subheadTop:'15%')}]"
      >这是一个副标题内容 这是一个副标题内容</div>
      <!-- 文案内容上 -->
      <div
        :class="{'content-box-top':flag}"
        :style="[{top:(content.top?content.top:'32%')},{left:(content.left?content.left:'50%')}]"
      >
        <div
          v-for="(item,index) in contentList"
          :key="index"
        >{{item.text}}</div>
      </div>
      <!-- 文案内容下 -->
      <div
        :class="{'content-box-bottom':flag}"
        :style="[{top:(contentMain.top?content.top:'63%')}]"
      >
        <div
          v-for="(item,index) in contentList"
          :key="index"
        >{{item.text}}</div>
      </div>
      <!-- 底部说明左 -->
      <div
        :class="{'footer-box-left':flag}"
        :style="[{top:(explainBottom_left.top?explainBottom_left.top:'91%')},{left:(explainBottom_left.left?explainBottom_left.left:'6%')}]"
      >
        <div
          v-for="(item,index) in footerList"
          :key="index"
        >
          {{'test this is test text'}}
        </div>
      </div>
      <!-- 底部说明右 -->
      <div
        :class="{'footer-box-right':flag}"
        :style="[{top:(explainBottom_right.top?explainBottom_right.top:'88%')},{left:(explainBottom_right.left?explainBottom_right.left:'75%')} ]"
      >
        <VueQr
          :text="qrcode_uri"
          :size="75"
          :margin="2"
        ></VueQr>
      </div>

    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import html2canvas from 'html2canvas'
export default {
  data () {
    return {
      flag: true,
      qrcode_uri: '',
      contentList: [{ text: '测试文字测试文字测' }, { text: 'test this is test text' }, { text: 'test this is test text' }, { text: 'test this is test text' }, { text: 'test this is test text' },],
      footerList: [{ text: 'test this is test text' }, { text: 'test this is test text' },],
      titleTop: '',
      subheadTop: '',
      content: { top: '', left: '' },
      contentMain: { top: '', },
      explainBottom_left: { top: '', left: '' },
      explainBottom_right: { top: '', left: '' }
    }
  },
  components: {
    VueQr
  },
  created () {
    setTimeout(() => {

    }, 1000)
  },
  mounted () {
    window.vue = this
    // this.saveHtml2Image()
    // setTimeout(this.saveHtml2Image, 2000)
    this.imgload_()
  },
  methods: {
    //图片加载监控
    imgload_ () {
      let imgList = document.getElementsByTagName('img');//图片集合
      let imgCount = imgList.length;//图片总数
      let imgLoad = 0;//加载完成的图片数量

      for (let i = 0; i < imgCount; i++) {
        imgList[i].onload = () => {
          imgLoad++;
          console.log(imgLoad);
          if (imgLoad === imgCount) {
            // img加载完成 do something
            console.log('图片全部加载完成');
            this.saveHtml2Image()
          }
        }
      }
    },

    //将html结构转换为图片
    saveHtml2Image () {

      //转换图片时 将高度归0 避免出现顶部白边
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;

      html2canvas(document.querySelector('#imgBox'), {
        useCORS: true,
        allowTaint: true,
      }).then(async canvas => {
        const img = document.createElement("img");
        let src = canvas.toDataURL("image/jpeg", 0.9)
        img.src = src
        document.body.appendChild(img);
        img.style.cssText =
          "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:9;";
        img.id = "poster-Img-new"
        //将base64符号转义处理 
        // encodeURIComponent(img.src)
        //转换完成后 将base64提交到后台
        console.log({ img: src, refer: this.refer, project: this.project })

      });
    },
  }
}
</script>

<style scoped>
.box {
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  line-height: 0;
}
img {
  width: 100%;
  object-fit: contain;
}
.title-box,
.content-box-bottom,
.footer-box-left,
.footer-box-right,
.content-box-top,
.subhead-box {
  position: absolute;
  line-height: 1;
}
.title-box {
  width: 100%;
  text-align: center;
  font-size: 22px;
  color: #fafbec;
}
.subhead-box {
  word-break: keep-all;
  width: 100%;
  text-align: center;
  font-size: 20px;
}
.content-box-top {
  width: 43%;

  letter-spacing: 1px;
  font-size: 16px;
  font-family: "Microsoft YaHei";
}
.content-box-bottom {
  width: 100%;
  padding: 5px 35px;
  letter-spacing: 1px;
  font-size: 16px;
  font-family: "Microsoft YaHei";
}
.footer-box-right {
  line-height: 0;
}
.footer-box-left {
  width: 67%;
}
</style>